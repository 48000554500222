import { Header } from './Header/Header';
import Footer from './Footer';

export const FlutterDeveloper = () => {
  return <div>
        <Header />
        <section class="bg-white pt-24">
        <div class="gap-16 py-4 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            
            <div class="grid justify-center lg:pt-20">
                <img src={'static/icon/flutter.svg'} alt="Flutter developer" width="350px" height="350px" />
            </div>

            <div class="font-light text-gray-500 sm:text-lg">
                <h2 class="mb-12 text-3xl font-bold text-gray-600">Flutter developer</h2>
                <p class="mb-4 font-medium text-xl text-gray-500"><strong>Job description</strong> [ Full Time | Remote Work ]
                    <br /><br />We are looking for an experienced Flutter developer to engineer applications that will run across Android, iOS, and the web. As a flutter developer, you will have experience building native applications, JavaScript knowledge, and a portfolio of mobile applications already published.
                </p>
                <p class="py-4 font-bold text-xl text-gray-500">Mandatory Skills:</p>
                <ul class="list-disc m-4">
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Have two or more iOS/Android apps developed with Flutter.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Either deployed on the AppStore/Google Play or available on Github.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">2+ years in mobile software development.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Over one year’s developing Flutter applications for mobile.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Experience with third-party libraries and APIs.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Understanding of the Agile development life-cycle.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Experience with automated testing and building.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Experience with Git, SVN, or other version control tools.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Knowledge of Java/Swift.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Understanding of Android Keyboard API.</p></li>
                    <li><p class="mb-2 font-medium text-lg text-gray-500">Experience with optimizing applications for Material Design.</p></li>
                </ul>
                <p class="mb-8 leading-relaxed text-lg font-medium">
                    <a class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg" href="/application">Apply now</a>
                </p>
            </div>
       
        </div>
    </section>
        <Footer />
  </div>
}