import React from 'react';

const Together = () => {
  return (
    <div>
      <section class="pt-20">
        <img
          src={'static/img/about-min.png'}
          alt="About-Chainscript"
          width="100%"
        />
      </section>
    </div>
  );
};

export default Together;
