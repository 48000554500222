import React from 'react';
import './TogetherCareer.css';

const TogetherCareer = () => {
    return <div>
        <section class="bg-white background_img pt-20">
            <div class="gap-16 items-center  mx-auto max-w-screen-xl py-32 px-8">
                <div class="font-light text-gray-500 sm:text-lg text-center">
                    <h2 class="text-5xl uppercase font-bold text-gray-300">Join The ChainScript Team</h2>
                </div>
            </div>
        </section>
    </div>
}

export default TogetherCareer