import React from 'react';
import './Team.css';

const Team = () => {
  return (
    <div>
      <section id="team" class="text-gray-400 body-font lg:pt-12 lg:mt-16">
        <div class="container px-5 py-24 mx-auto">
          <h1 class="text-3xl font-medium text-center text-gray-800 capitalize lg:text-4xl">
            Team
          </h1>
          <p class="mx-auto my-6 text-center text-xl font-medium text-gray-500 mb-8 lg:mb-24">
            Meet the super stars
          </p>
          <div class="flex flex-wrap -m-4 justify-center">
            <div class="lg:w-1/4 md:w-1/2 p-4 flex justify-center">
              <div class="flex relative">
                <div class="w-60 h-72 profile-background bg-white bg-gradient-to-r from-gray-300 to-gray-400 rounded-lg shadow-md ">
                  <img
                    class="rounded-lg w-48 h-48 mt-5 mx-auto"
                    src={'static/img/profile/sivakarthikeyan.jpeg'}
                    alt=""
                  />
                  <div class="flex items-center justify-between px-5 py-2">
                    <div class="pl-1">
                      <h5 class="mb-1 text-lg font-bold tracking-tight text-gray-700">
                        Sivakarthikeyan
                      </h5>
                      <p class="font-normal text-gray-800">
                        Software Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 flex justify-center">
              <div class="flex relative">
                <div class="w-60 h-72 profile-background bg-white bg-gradient-to-r from-gray-300 to-gray-400 rounded-lg shadow-md ">
                  <img
                    class="rounded-lg w-48 h-48 mt-5 mx-auto"
                    src={'static/img/profile/mathumitha.jpeg'}
                    alt=""
                  />
                  <div class="flex items-center justify-between px-5 py-2">
                    <div class="pl-1">
                      <h5 class="mb-1 text-lg font-bold tracking-tight text-gray-700">
                        Mathumitha
                      </h5>
                      <p class="font-normal text-gray-800">
                        Front-end Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 flex justify-center">
              <div class="flex relative">
                <div class="w-60 h-72 profile-background bg-white bg-gradient-to-r from-gray-300 to-gray-400 rounded-lg shadow-md ">
                  <img
                    class="rounded-lg w-48 h-48 mt-5 mx-auto"
                    src={'static/img/profile/Aditya.jpg'}
                    alt=""
                  />
                  <div class="flex items-center justify-between px-5 py-2">
                    <div class="pl-1">
                      <h5 class="mb-1 text-lg font-bold tracking-tight text-gray-700">
                        Adithya
                      </h5>
                      <p class="font-normal text-gray-800">Flutter Developer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 flex justify-center">
              <div class="flex relative">
                <div class="w-60 h-72 profile-background bg-white bg-gradient-to-r from-gray-300 to-gray-400 rounded-lg shadow-md ">
                  <img
                    class="rounded-lg w-48 h-48 mt-5 mx-auto"
                    src={'static/img/profile/karthi_cs.png'}
                    alt=""
                  />
                  <div class="flex items-center justify-between px-5 py-2">
                    <div class="pl-1">
                      <h5 class="mb-1 text-lg font-bold tracking-tight text-gray-700">
                        Karthigeyan
                      </h5>
                      <p class="font-normal text-gray-800">Intern</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
