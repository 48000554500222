import React from 'react';

const Associates = () => {
    return <div>
    <section class="text-gray-100">
      <div class="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
        <h2 class="mb-8 lg:mb-16 text-4xl font-medium text-center text-gray-800 md:text-4xl">Associates</h2>
        <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-5">
          <div class="flex justify-center items-center">
            <img src={'static/img/bsv-blockchain.png'} alt="BSV Bitcoin Association" class="h-20" />                                               
          </div>
          <div class="flex justify-center items-center">
            <img src={'static/img/BlockDojo_new_logo.png'} alt="Blockdojo" class="h-20" />                                                                                  
          </div>
          <div class="flex justify-center items-center">
            <img src={'static/img/Part_BR.png'} alt="BlockReview" class="h-20" />                                                                 
          </div>
          <div class="flex justify-center items-center">
            <img src={'static/img/Ipwe_logo.png'} alt="Ipwe" class="h-20" />                                                                                   
          </div>
          <div class="flex justify-center items-center">
            <img src={'static/img/intrasettle.png'} alt="Intrasettle" class="h-32" />                                                                                    
          </div>
        </div>
      </div>
    </section>
    </div>
}

export default Associates