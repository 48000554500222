import { Header } from './Header/Header';
import Footer from './Footer';

export const FullStackDeveloper = () => {
  return <div>
        <Header />
        <section class="bg-white pt-24">
            <div class="gap-16 py-4 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                
                <div class="grid justify-center lg:pt-20">
                    <img src={'static/icon/full.svg'} alt="Full-stack developer" width="350px" height="350px" />
                </div>

                <div class="font-light text-gray-500 sm:text-lg">
                    <h2 class="mb-12 text-3xl font-bold text-gray-600">Full-stack developer</h2>
                    <p class="mb-4 font-medium text-xl text-gray-500"><strong>Job description</strong> [ Full Time | Remote Work ]
                        <br /><br />We are looking for an experienced full stack developer with a keen interest to learn blockchain development. As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, development frameworks and third-party libraries. You should also be a team player with a knack for visual design and utility.
                        <br /><br />You’ll be a key member of the team tasked with contributing to a number of high-value projects
                    </p>
                    <p class="py-4 font-bold text-xl text-gray-500">Requirements:</p>
                    <ul class="list-disc m-4">
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Work with development teams and product managers to ideate software solutions.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Design client-side and server-side architecture.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Build the front-end of applications through appealing visual design.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Develop and manage well-functioning databases and application.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Write effective APIs.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Test software to ensure responsiveness and efficiency.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Troubleshoot, debug and upgrade software.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Create security and data protection settings.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Build features and applications with a cross-platform responsive design.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Write technical documentation.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Work with data scientists and analysts to improve software.</p></li>
                    </ul>
                    <p class="py-4 font-bold text-xl text-gray-500">Mandatory Skills:</p>
                    <ul class="list-disc m-4">
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Knowledge and experience of all stages of software development in a highly agile working environment.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Experience of design, development, testing and deployment of web applications on a cloud environment.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Proven experience in writing readable code, creating extensive documentation for existing code, and refactoring previously written code.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Hands-on development experience using ReactJs, NodeJS, PHP and Python.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Hands-on experience in designing and developing applications using cross platform frameworks and microservices.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Knowledge of multiple front-end languages and libraries (e.g. HTML/ CSS, JavaScript, XML, jQuery).</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Knowledge of multiple back-end languages (e.g. C#, Java, Python) and JavaScript frameworks (e.g. Angular, React, Node.js).</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Familiarity with databases (e.g. MySQL, MongoDB), web servers (e.g. Apache) and UI/UX design.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Expert in integration & development of 3rd third party and custom API’s.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Knowledge of Go.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Strong Agile development experience.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Experience with automated testing and building.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Experience with Git, SVN, or other version control tools.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Enjoy solving problems.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Able to work on your own initiative.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Must be proactive and take ownership from start till closure.</p></li>
                    </ul>
                    <p class="mb-8 leading-relaxed text-lg font-medium">
                        <a class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg" href="/application">Apply now</a>
                    </p>
                </div>
        
            </div>
        </section>
        <Footer />
  </div>
}