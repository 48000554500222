import React from 'react';

const Gallery = () => {
  return (
    <div>
      <section class="">
        <div class="items-center px-4 mx-auto max-w-screen-xl">
          <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="text-4xl font-medium text-gray-800">Gallery</h2>
          </div>
        </div>
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="flex justify-center">
            <img class="rounded-lg" src={'static/img/office_6.jpg'} alt="" />
          </div>
          <div class="flex justify-center">
            <img class="rounded-lg" src={'static/img/office_1.jpg'} alt="" />
          </div>
          <div class="flex justify-center">
            <img class="rounded-lg" src={'static/img/office_5.jpg'} alt="" />
          </div>
          <div class="flex justify-center">
            <img class="rounded-lg" src={'static/img/office_2.jpg'} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
