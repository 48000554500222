import { Routes, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { About } from './components/About';
import { Career } from './components/Career';
import { Devschool } from './components/Devschool';
import { Contact } from './components/Contact';
import Application from './components/Application/Application';
import { CookieNotice } from './components/CookieNotice/CookieNotice';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { TermsOfUse } from './components/TermsOfUse/TermsOfUse';
import { Shop } from './components/shop';
import { GraphicDesigner } from './components/GraphicDesigner';
import { FullStackDeveloper } from './components/FullStackDeveloper';
import { FlutterDeveloper } from './components/FlutterDeveloper';
import { NodeDeveloper } from './components/NodeDeveloper';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/career" element={<Career />}></Route>
      <Route path="/devschool" element={<Devschool />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/application" element={<Application />}></Route>
      <Route path="/cookies" element={<CookieNotice />}></Route>
      <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
      <Route path="/termsofuse" element={<TermsOfUse />}></Route>
      <Route path="/shop" element={<Shop />}></Route>
      <Route path="/graphic-designer" element={<GraphicDesigner />}></Route>
      <Route
        path="/full-stack-developer"
        element={<FullStackDeveloper />}
      ></Route>
      <Route path="/flutter-developer" element={<FlutterDeveloper />}></Route>
      <Route path="/node-developer" element={<NodeDeveloper />}></Route>
    </Routes>
  );
}

export default App;
