import { Header } from './Header/Header';
import Footer from './Footer';
import { HeroCarousel }  from './Home/HeroCarousel/HeroCarousel';
import Hero from './Home/Hero/Hero';
import WhatWeOffer from './Home/WhatWeOffer/WhatWeOffer';
import Animation from './Home/Animation/Animation';
import Features from './Home/Features/Features';
import Associates from './Home/Associates';
import Workwithus from './Home/Workwithus';

export const Home = () => {
    return <div>
        <Header />
        <HeroCarousel />
        <Hero />
        <WhatWeOffer />
        <Animation />
        <Features />
        <Associates />
        <Workwithus />
        <Footer />
    </div>
}