import React from 'react';

const Footer = () => {
    return <div>
    <footer class="p-4 sm:p-6 lg:py-16 lg:px-24 border-t border-gray-600 container mx-auto">
      <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0">
          <a href="/" class="flex pl-2 items-center pb-2">
            <img src={'static/icons/cs_logo.svg'} alt="Chainscript" class="h-12" />
          </a>

          <div class="font-light pl-6 text-gray-500 sm:text-sm">
            <p class="mb-4 font-medium text-sm text-gray-500">13/4 Golf Court Building, <br />Fanpet 1st Street, Nandanam, <br />Chennai TN 600035 IN</p>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-16 sm:gap-6 sm:grid-cols-2 lg:py-4 lg:pb-8">
          
        <div class="lg:mr-12">
          <h2 class="mb-12 text-sm font-semibold text-gray-900 uppercase">Follow us</h2>
          <ul class="text-gray-600">
            <li class="mb-8">
              <a href="https://www.linkedin.com/company/chainscript-labs-pvt-ltd/" class="hover:underline">LinkedIn</a>
            </li>
            <li class="mb-8">
              <a href="https://mobile.twitter.com/Chainscript" class="hover:underline">Twitter</a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCabklVnIBEJM8hBhFDnXg3w" class="hover:underline ">YouTube</a>
            </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-12 text-sm font-semibold text-gray-900 uppercase">Legal</h2>
            <ul class="text-gray-600">
              <li class="mb-8">
                <a href="/cookies" class="hover:underline">Cookie Notice</a>
              </li>
              <li class="mb-8">
                <a href="/privacypolicy" class="hover:underline">Privacy Policy</a>
              </li>
              <li>
                <a href="/termsofuse" class="hover:underline">Terms of Use</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
        <span class="text-sm text-gray-500 sm:text-center">© 2022 <a href="/" class="hover:underline">ChainScript.dev</a>. All Rights Reserved.
        </span>
        <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/chainscript-labs-pvt-ltd/" class="text-gray-500 hover:text-gray-900">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24" >
              <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
              <title>Logo linkedIn</title>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
            <span class="sr-only">LinkedIn page</span>
          </a>
          <a target="_blank" rel="noreferrer" href="https://mobile.twitter.com/Chainscript" class="text-gray-500 hover:text-gray-900">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path></svg>
            <span class="sr-only">Twitter page</span>
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCabklVnIBEJM8hBhFDnXg3w" class="text-gray-500 hover:text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon w-5 h-5" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" viewBox="0 0 512 512" >
              <path d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z" />
            </svg>
            <span class="sr-only">Youtube page</span>
          </a>
        </div>
      </div>
    </footer>
    </div>
}

export default Footer