class SendEmail {
  // Demo request form
  sendApplicationForm(title, email, mobile, message) {
    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: title,
        email: email,
        mobile: mobile,
        message: message,
      }),
    };
    return fetch(
      'https://intrasettle-server.web.app/server/chain/application',
      payload
    )
      .then((response) => response.json())
      .then((response) => {
        // alert("success");
        console.log('Success:', response);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  // Contact form
  sendContactForm(title, email, mobile, message) {
    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: title,
        email: email,
        mobile: mobile,
        message: message,
      }),
    };
    return fetch(
      'https://intrasettle-server.web.app/server/chain/contact',
      payload
    )
      .then((response) => response.json())
      .then((response) => {
        // alert("success");
        console.log('Success:', response);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  // Invest enquiry form
  sendWorkwithusForm(title, email, company, mobile, message) {
    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: title,
        email: email,
        company: company,
        mobile: mobile,
        message: message,
      }),
    };
    return fetch(
      'https://intrasettle-server.web.app/server/chain/workwithus',
      payload
    )
      .then((response) => response.json())
      .then((response) => {
        // alert("success");
        console.log('Success:', response);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }
}

export { SendEmail };
