import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <div>
      <section class="pt-8 bg-gray-100 lg:pt-8">
        <div class="gap-16 items-center py-4 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="mb-10 text-5xl lg:text-6xl font-medium text-gray-800">
              Why Choose <br />Chainscript?
            </h2>
            <p class="mb-4 font-medium text-lg text-gray-500">
              As pioneers in BSV application development in India, Chainscript leverages the region's vast software talent pool to bring you cost-effective, high-quality, and scalable BSV blockchain solutions.
              <br />
              <br />
              Our expert in-house team is dedicated to crafting efficient, tailored solutions that seamlessly transition your business into the blockchain era.
              <br />
            </p>
          </div>
          <div class="flex justify-center">
            <lottie-player
              class="lotte-file"
              src="https://assets7.lottiefiles.com/packages/lf20_kndsasgl.json"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
