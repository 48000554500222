import React , { useEffect } from 'react';
import './HeroCarousel.css';

const HeroCarousel = () => {
  useEffect(() => {

    const delay = 10000; //ms
    const slides = document.querySelector('.slides');
    const slidesCount = slides.childElementCount;
    const maxLeft = (slidesCount - 1) * 100 * -1;
    
    let current = 0;
    
    const changeSlide = (next = true) => {
      if (next) {
        current += current > maxLeft ? -100 : current * -1;
      } else {
      current = current < 0 ? current + 100 : maxLeft;
    }
    
    slides.style.left = current + '%';
  }
  
  let autoChange = setInterval(changeSlide, delay);
  const restart = function () {
    clearInterval(autoChange);
    autoChange = setInterval(changeSlide, delay);
  };
  
  // Controls
  document.querySelector('.next-slide').addEventListener('click', function () {
    changeSlide();
    restart();
  });
  
  document.querySelector('.prev-slide').addEventListener('click', function () {
    changeSlide(false);
    restart();
  });
  
}, []);
    return (
    <div>
    <section class="bg-gray-100 pt-24">
      <div class="carousel">
        <div class="slides">


          <div class="pt-20 lg:pt-24 slide items-center">
            <div class="gap-16 lg:items-center pt-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">

              <div class="font-light text-gray-500 sm:text-lg">
                  <h2 class="mb-8 text-3xl lg:text-4xl font-bold text-gray-600">Unlock the Power of Blockchain for Unmatched Data Integrity</h2>
                  <p class="mb-8 font-medium text-lg lg:text-xl text-gray-500">Elevate your business with the leading-edge solutions at the heart of the blockchain evolution, and gain a competitive edge in the digital marketplace.</p>
                  <div class="flex justify-start">
                    <a href="#workwithus" class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg">Work with us</a>
                    <a href="/about" class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded-3xl text-lg">Learn more</a>
                  </div>
              </div>
              <div class="flex justify-center lotte_section">
                <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_uzvwjpkq.json" class="lotte_file"  background="transparent"  speed="1"    loop  autoplay></lottie-player>
              </div>
            </div>
          </div>

          <div class="pt-20 lg:pt-24 slide">
            <div class="gap-16 lg:items-center pt-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
     
              <div class="flex justify-center lotte_section">
                <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_t4yjawml.json" class="lotte_file"  background="transparent"  speed="1"    loop  autoplay></lottie-player>

              </div>
        
              <div class="font-light text-gray-500 sm:text-lg">
                  <h2 class="mb-8 text-2xl font-bold text-gray-600">Asatoma Sadgamaya! Tamaso Ma Jyotirgamaya!
                    <br />Mrityorma Amritamgamaya! Om Shanthi Shanthi Shanthi!
                  </h2>
                  <h2 class="mb-8 text-xl font-bold text-gray-600">
                    சான்றோர்க்குப் பொய்யா விளக்கே விளக்கு
                  </h2>
                  <p class="mb-4 font-medium text-2xl text-purple-500">Darkness to light, moving data on chain</p>
              </div>
            </div>
          </div>

          <div class="pt-20 lg:pt-24 slide">
            <div class="gap-16 lg:items-center pt-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
              <div class="font-light text-gray-500 sm:text-lg">
                <h2 class="mb-8 text-3xl font-bold text-gray-600">LEARN</h2>
                <p class="mb-4 font-medium text-xl text-gray-500">
                  Transform into a skilled software developer in just four months – at no cost to you.
                </p>
                <p class="mb-8 font-medium text-xl text-gray-500">
                  Our developer learning program is meticulously crafted to equip you with the most sought-after skills in web development, preparing you to join our team swiftly. Dive into specific technologies, and start your journey towards a dynamic career in web development.
                </p>
                <div class="flex justify-start">
                  <a href="/devschool" class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg">Begin Learning Today</a>
                </div>
              </div>
              <div class="flex justify-center lotte_section">
                <iframe class="rounded-lg border-5 border-gray-500 shadow-xl lotte_video" src="https://www.youtube.com/embed/mI3A8H0oblg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
 
          <div class="pt-20 lg:pt-24 slide">
            <div class="gap-16 lg:items-center pt-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
              <div class="flex justify-center lotte_section">
                <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_vw2a6ckt.json" class="lotte_file"  background="transparent"  speed="1"    loop  autoplay></lottie-player>
              </div>
              <div class="font-light text-gray-500 sm:text-lg">
                  <h2 class="mb-8 text-3xl font-bold text-gray-600">Build Your Career with Chainscript</h2>
                  <p class="mb-8 font-medium text-xl text-gray-500">At Chainscript, we value professionals who have a keen eye for detail, a commitment to continuous learning, and strong self-management skills. With these qualities, you can be assured of a rewarding and enriching career.</p>
                  <p class="mb-8 font-medium text-xl text-gray-500">Embark on your professional journey with us.</p>
                  <a href="/application" class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg">Apply now</a>
                </div>
            </div>
          </div>


        </div>
        <div class="controls">
            <div class="control prev-slide">&#x25C4;</div>
            <div class="control next-slide">&#x25BA;</div>
        </div>
    </div>
    </section>
    </div>
  )
}

export { HeroCarousel };
