import { Header } from '../Header/Header';
import Footer from '../Footer';
import './CookieNotice.css';

export const CookieNotice = () => {
    return <div>
        <Header />
        <section class="py-24 text-lg font-medium">
            <div class="">
                <h1 class="cookie-tittle">Cookies</h1>
            </div>

            <div class="cookie-line"></div>

            <div class="cookie-content">
                <p class="cookie-content-para">
                    The Site uses cookies to distinguish you from other users. This helps
                    us provide you with a good experience when browsing, and also allows
                    us to improve our Site
                </p>
                <h3  class="cookie-content-heading">Your privacy is important to us.</h3>
                <p class="cookie-content-para">
                    A cookie is a small file of letters and numbers that we store on your
                    browser or the hard drive of your computer if you agree. Cookies
                    contain information that is transferred to your computer’s hard drive.
                </p>
                <p class="cookie-content-para">We use or may use the following cookies:</p>
                <li class="cookie-content-list">
                    Strictly necessary cookies. These are cookies that are required for
                    the operation of our Site. They include, for example, cookies that
                    enable you to log into secure areas of our Site.
                </li>
                <li class="cookie-content-list">
                    Analytical/performance cookies. They allow us to recognise and count
                    the number of visitors and to see how visitors move around our Site
                    when they are using it. This helps us to improve the way our Site
                    works, for example, by ensuring that users are finding what they are
                    looking for easily.
                </li>
                <li class="cookie-content-list">
                    Functionality cookies. These are used to recognise you when you return
                    to our Site. This enables us to personalise our content for you, greet
                    you by name and remember your preferences.
                </li>
                <li class="cookie-content-list">
                    Targeting cookies. These cookies record your visit to our Site, the
                    pages you have visited and the links you have followed. We will use
                    this information to make our Site and advertising displayed on it (if
                    any) more relevant to your interests. We may also share this
                    information with third parties for this purpose
                </li>
                <p class="cookie-content-para">
                    Please note that third parties (including, for example, advertising
                    networks and providers of external services like web traffic analysis
                    services) may also use cookies, over which we have no control.
                </p>
                    You can block cookies by activating the setting on your browser that
                    allows you to refuse the setting of
                <p class="cookie-content-para">
                    all or some cookies. However, if you use your browser settings to
                    block all cookies (including essential cookies) you may not be able to
                    access all or parts of our Site.
                </p>
                <p class="cookie-content-para">
                    Most cookies (other than essential cookies) will expire within four
                (4) days.
                </p>
                <h3  class="cookie-content-heading">Contact</h3>
                <p class="cookie-content-para">
                    If you have any questions or concerns about how we treat your personal
                    information, you wish to ask us to stop processing your personal
                    information, or you would like to request a copy of the personal
                    information we hold about you, please email us at 
                    <a class="text-blue-500" href="mailto:contact@chainscript.in"> contact@chainscript.in</a>
                </p>
            </div>
        </section>        
        <Footer />
    </div>
}