import React from 'react';

const DevCourse = () => {
  const programminglanguages = [
    {
        id: 1,
        title: 'HTML',
        link: 'static/icon/html_logo.png'
    },
    {
        id: 2,
        title: 'CSS',
        link: 'static/icon/css_logo.png'
    },
    {
        id: 3,
        title: 'JavaScript',
        link: 'static/icon/js.png'
    },
    {
        id: 4,
        title: 'NodeJS',
        link: 'static/icon/nodejs-logo.svg'
    },
    {
        id: 5,
        title: 'ReactJS',
        link: 'static/icon/React.png'
    },
    {
        id: 6,
        title: 'SvelteJS',
        link: 'static/icon/Svelte_Logo.png'
    },
    {
        id: 7,
        title: 'Flutter',
        link: 'static/icon/flutter.png'
    },
    // {
    //     id: 8,
    //     title: 'Ruby',
    //     link: 'static/icon/ruby.png'
    // },
    // {
    //     id: 9,
    //     title: 'Rails',
    //     link: 'static/icon/rails.png'
    // },
    // {
    //     id: 10,
    //     title: 'PHP',
    //     link: 'static/icon/new-php-logo.svg'
    // },
  ]
  const designtools = [
    {
        id: 1,
        title: 'Photoshop',
        link: 'static/icon/photoshop.png'
    },
    {
        id: 2,
        title: 'Lightroom',
        link: 'static/icon/photoshop-lightroom.png'
    },
    {
        id: 3,
        title: 'Illustrator',
        link: 'static/icon/illustrator.png'
    },
    {
        id: 4,
        title: 'xd',
        link: 'static/icon/xd.png'
    },
    {
        id: 5,
        title: 'InDesign',
        link: 'static/icon/React.png'
    },
    {
        id: 6,
        title: 'Premiere Pro',
        link: 'static/icon/premiere-pro.png'
    },
    {
        id: 7,
        title: 'After Effects',
        link: 'static/icon/after-effects.png'
    },
    {
        id: 8,
        title: 'Figma',
        link: 'static/icon/figma.png'
    },
    {
        id: 9,
        title: '3dsMax',
        link: 'static/icon/3dsmax.png'
    },
    {
        id: 10,
        title: 'V-ray',
        link: 'static/icon/v-ray-logo.png'
    },
    {
        id: 11,
        title: 'davinci_resolve',
        link: 'static/icon/davinci_resolve.png'
    },
    {
        id: 12,
        title: 'affinity',
        link: 'static/icon/affinity.png'
    },
  ]
    return <div>
    <section class=" pb-24 lg:pt-12">
      <div class="py-4 mx-auto max-w-screen-xl px-4">

        <div class="font-light text-center text-gray-500 sm:text-lg dark:text-gray-400">
          <span class="inline-block h-1 w-20 rounded bg-gray-700 mt-4 mb-6"></span>
          <p class="mb-24 font-medium text-4xl text-gray-800">Programming languages</p>

        </div>
        <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-4 lg:grid-cols-7 dark:text-gray-400">
          {
            programminglanguages.map(({ id, title, link }) => (
              <div key={id} class="flex justify-center items-center">
                <img src={link} alt={title} class="h-16" />                                                                                    
              </div>
            ))
          }      
        </div>
      </div>
    </section>
    <section class=" pb-16 lg:pt-12">
      <div class="py-4 mx-auto max-w-screen-xl px-4">
        <div class="font-light text-center text-gray-500 sm:text-lg dark:text-gray-400">
          <span class="inline-block h-1 w-20 rounded bg-gray-700 mt-4 mb-6"></span>
          <p class="mb-24 font-medium text-4xl text-gray-800">Design tools</p>

        </div>
        <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-4 lg:grid-cols-7 dark:text-gray-400">
          {
            designtools.map(({ id, title, link }) => (
              <div key={id} class="flex justify-center items-center">
                <img src={link} alt={title} class="h-16" />                                                                                    
              </div>
            ))
          }
        </div>
      </div>
    </section>

    <section class="text-gray-600 ">
      <div class="container px-5 py-24 mx-auto border-t border-gray-500">
        <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <p class="mb-4 font-medium text-xl text-gray-500">Unlock the Magic of Learning in Your Own Language!
            <br /><br />Ever noticed how things just 'click' when explained in your mother tongue? That's not just a feeling – it's a fact! Studies reveal that learning in your native language can significantly boost your understanding and retention.
            <br /><br />Welcome to a world where learning isn't just effective, it's comfortable and familiar. Our courses on Bitcoin, programming, networking, and design aren't just in any language – they're in your language. Whether it's Hindi, Tamil, Bengali, or any of the rich tapestry of Indian languages, we've got you covered.
            <br /><br />Crafted by industry wizards, these courses break down complex concepts into easily digestible lessons, all in the language that speaks to your heart. The cherry on top? It's all absolutely free!
            <br /><br />So why wait? Dive into learning that feels like home.
            <br /><br /><a href="https://youtube.com/playlist?list=PLk7U-_g00Rukm3WrTDykaYFgRY7yZVSs4&si=JzAjVrwWnuar8VBl">
                Jump into our Free Courses – Learn in Your Mother Tongue Today!
              </a>
          </p>
          <span class="inline-block h-1 w-10 rounded bg-gray-400 mt-8 mb-6"></span>
        </div>
      </div>
    </section>
    </div>
}

export default DevCourse
