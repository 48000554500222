import React, { useState, useRef } from 'react';
import './DevAccordion.css';

const faqs = [
    {
        id: 1,
        header: "Technical Skills",
        list1: "HTML & CSS",
        list2: "JavaScript",
        list3: "Express.js",
        list4: "Node.js",
        list5: "Reactjs",
        list6: "Svelte",
        list7: "Flutter",
        list8: "Ruby",
        list9: "Ruby on Rails",
        list10: "Databases",
        list11: "Algorithms",
        list12: "Data Structures",
        list13: "sCrypt",
        list14: "BitcoinSV",
        list15: "And so on ..."
    },
    {
        id: 2,
        header: "Design Skills",
        list1: "Photoshop",
        list2: "Lightroom",
        list3: "Illustrator",
        list4: "Xd",
        list5: "Indesign",
        list6: "Figma",
        list7: "Premiere Pro",
        list8: "After effects",
        list9: "3ds Max",
        list10: "V-Ray",
        list11: "DaVinci Resolve",
        list12: "Affinity",
        list13: "And so on ..."
    },
    {
        id: 3,
        header: "Soft Skills",
        list1: "Time Management",
        list2: "Project Management",
        list3: "Remote Work Best Practices",
        list4: "Effective Networking",
        list5: "Cross-cultural Communication",
        list6: "And so on ..."
    }
]

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, list1, list2, list3, list4, list5, list6, list7, list8, list9, list10, list11, list12, list13, list14, list15} = faq;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="text-lg text-gray-600 font-bold">{header}</h5>
                    <i className="fa fa-plus rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list1}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list2}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list3}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list4}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list5}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list6}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list7}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list8}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list9}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list10}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list11}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list12}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list13}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list14}</p>
                    <p className='mb-2 text-lg font-medium text-gray-500'>{list15}</p>
                 
                </div>
            </div>
        </div>
    )
}

const DevAccordion = () => {
    
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    
    return (
        <>
            <div className="container-fluid mt-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-2">
                        <div className="rc-card">
                            <div className="card-body">
                                {faqs.map((faq, index) => {
                                     return (
                                            <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { DevAccordion }