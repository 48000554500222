import React from 'react';
import './WhatWeOffer.css'

const WhatWeOffer = () => {
  return <div>
    <section class="bg-gray-100"> 
      <div id="services" class="section relative pt-20 pb-20 md:pt-16 md:pb-0 ">
        <div class="container xl:max-w-6xl mx-auto px-4">

          <div class="font-light text-center text-gray-500 sm:text-lg dark:text-gray-400">
            <span class="inline-block h-1 w-20 rounded bg-gray-700 mt-4 mb-6"></span>
            <p class="mb-24 font-medium text-4xl text-gray-800">What we offer</p>
          </div>

          <div class="flex flex-wrap flex-row -mx-4 text-center pb-12">
            
            <div class="icon-1 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/Application_development.svg'} width="50px" alt="Application_development" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Application  <br /> development</h3>
              </div>
            </div>
            
            <div class="icon-2 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/consultancy.svg'} width="40px" alt="Consultancy" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Blockchain <br />consultancy</h3>
              </div>
            </div>
            
            <div class="icon-1 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/proof_of_concept.svg'} width="30px" alt="proof_of_concept" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Proof of concept <br />creation</h3>
              </div>
            </div>

            <div class="icon-2 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/innovation_and_evaluation_of_ideas.svg'} width="30px" alt="innovation_and_evaluation_of_ideas" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Innovation and evaluation <br />of ideas</h3>
              </div>
            </div>

            <div class="icon-3 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/patented_concepts.svg'} width="30px" alt="patented_concepts" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Patented <br />concepts</h3>
              </div>
            </div>

            <div class="icon-3 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/waller_services.svg'} width="30px" alt="waller_services" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Wallet <br />services</h3>
              </div>
            </div>

            <div class="icon-3 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/token_services.svg'} width="30px" alt="token_services" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Tokenisation <br />services</h3>
              </div>
            </div>

            <div class="icon-3 flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out shadow hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <img src={'static/icon/database_services.svg'} width="24px" alt="database_services" />
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-700">Database <br />services</h3>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
}

export default WhatWeOffer
