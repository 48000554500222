import { Header } from './Header/Header';
import Footer from './Footer';
import Together from './About/Together';
import Inspire from './About/Inspire';
import Leaders from './About/Leaders/Leaders';
import Team from './About/Team/Team';
import Office from './About/Office';
import Gallery from './About/Gallery';

export const About = () => {
    return <div>
        <Header />
        <Together />
        <Inspire />
        <Leaders />
        <Team />
        <Office />
        <Gallery />
        <Footer />
    </div>
}