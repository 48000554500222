import React from 'react';

const DevHero = () => {
  return (
    <div>
      <section class="pt-20">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="mb-12 text-5xl font-medium text-gray-800">Dev School</h2>
            <p class="mb-4 font-medium text-lg text-gray-500">
              Dev School: Transform Into a Software Developer in Four Months
              <br />
              <br />
              Embark on a transformative journey with our Dev School program – completely free of charge. Our developer learning program is meticulously designed to equip you with the most sought-after skills in web development, enabling you to join our team swiftly.
              <br />
              <br />
              In this intensive, full-time, four-month program, you will:
              <br />
              <br />
              <ul class="list-disc pl-4">
                <li>Acquire essential technical skills.</li>
                <li>Learn new programming languages and frameworks.</li>
                <li>Master project management tools.</li>
                <li>Enhance your soft skills, crucial for career excellence.</li>
              </ul>
              <br />
              <br />
              Upon completion, you'll have the opportunity to join ChainScript as a trainee developer, setting the stage for a thriving career in software development.
              <br />
              <br />
              Ready to leap into your future?
              <br />
              <br />
              <a
                class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg"
                href="/application"
              >
                Apply Now!
              </a>
            </p>
          </div>

          <div class="flex justify-center">
            <img
              class="h-120"
              src={'static/icon/Devschool-shadow.svg'}
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default DevHero;
