import React from 'react';

const Inspire = () => {
  return (
    <div>
      <section class="pt-8 lg:pt-24">
        <div class="gap-16 items-center pb-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:pb-16 lg:px-6">
          <div class="p-12">
            <img src={'static/stickers/bsv_cs.png'} alt="About-Us" />
          </div>
          <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <p class="font-medium text-xl text-gray-500 pb-8">
              Inspired by{' '}
              <strong>
                <a
                  class="text-purple-500 underline"
                  href="https://craigwright.net/about/"
                >
                  Dr Craig S Wright's
                </a>
              </strong>
                vision of blockchain, our company is dedicated to developing on-chain applications that foster honesty in money, integrity in data, and transparency in systems, while building trust and efficiency in our networks.
            </p>
            <p class="font-medium text-xl text-gray-500 pb-8">
              Embracing the philosophy of{' '}
              <strong>
                <a
                  class="text-purple-500 underline"
                  href="https://en.wikipedia.org/wiki/Sridhar_Vembu"
                >
                  Zoho’s Sridhar Vembu
                </a>
              </strong>
              , we've strategically located our office in the serene valleys of Western Ghats, overlooking the lush paddy fields near Tenkasi. This choice reflects our commitment to counteracting urbanization and promoting sustainable village living.
            </p>
            <p class="font-medium text-xl text-gray-500 pb-8">
              Guided by the insights of{' '}
              <strong>
                <a
                  class="text-purple-500 underline"
                  href="https://www.linkedin.com/in/sureshceo/"
                >
                  Suresh
                </a>
              </strong>{' '}
              and{' '}
              <strong>
                <a
                  class="text-purple-500 underline"
                  href="https://www.youtube.com/channel/UCjAwopTaJUR5yXDzjg_8E_A"
                >
                  George
                </a>
              </strong>{' '}
              from Macappstudios, we believe that a formal education from prestigious institutions isn't the sole path to becoming a skilled engineer or a successful entrepreneur. Passion, hard work, and self-belief are equally crucial. Therefore, we welcome individuals with or without a degree, provided they have a relentless commitment to learning and a burning desire to succeed.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Inspire;
