import React from 'react';
import { DevAccordion } from './DevAccordion';

const Devprogram = () => {

  const programme = [
    {
        id: 1,
        title: 'No cost learning'
    },
    {
        id: 2,
        title: 'World-class curriculum'
    },
    {
        id: 3,
        title: 'Dynamic syllabus'
    },
    {
        id: 4,
        title: 'One-one guidance'
    },
    {
        id: 5,
        title: 'Peer learning'
    },
    {
        id: 6,
        title: 'Real-world workplace'
    },
    {
        id: 7,
        title: 'High-speed Internet'
    },
    {
        id: 8,
        title: 'Individual workspace'
    },
  ]

  return <div>
    <section id="devschool" class="pt-8 lg:pt-24">
      <div class="pb-4 pl-8 mx-auto max-w-screen-xl">
        <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="mb-4 text-4xl font-medium text-gray-800">Dev School Programme</h2>
        </div>
      </div>
      <div class="gap-16 pb-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:pb-16 lg:px-6">
        
        <div class="font-light text-gray-500 sm:text-lg">

          <div class="px-4 mx-auto max-w-screen-xl ">

            <div class="flex flex-wrap sm:mx-auto sm:mb-2 -mx-2">
              {
                programme.map(({ id, title }) => (
                  <div key={id} class="pt-2 w-full">
                    <div class="bg-gray-100 rounded flex p-2 h-full items-center">
                      <svg fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-purple-500 w-4 h-4 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                      <span class="title-font font-medium text-lg">{title}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
 
          <div class="">
            <div class="container px-4">
              <div class="max-w-4xl mx-auto">
                <DevAccordion />
              </div>
            </div>
          </div>
      </div>
    </section>
    
  </div>
}

export { Devprogram }