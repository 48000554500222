import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header } from '../Header/Header';
import Footer from '../Footer';
import './Application.css';
import { SendEmail } from '../SendEmail';

const Application = () => {
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');

  const showSuccess = () => {
    toast.info('Successfully sent you request', {
      position: 'bottom-left',
      // autoClose: 5000,
      hideProgressBar: true,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // theme: 'colored',
      // theme: 'dark',
    });
  };

  const sendEmail = new SendEmail();
  const sendRequest = async () => {
    sendEmail.sendApplicationForm(title, email, mobile, message);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    // alert('successfully sent you request');
    showSuccess();
    sendRequest();

    setTitle(' ');
    setEmail(' ');
    setMobile(' ');
    setMessage(' ');
  };

  return (
    <div>
      <ToastContainer />
      <Header />
      <section class="bg-white background_img_apply pt-20">
        <div class="gap-16 items-center  mx-auto max-w-screen-xl py-32 px-8">
          <div class="font-light text-gray-500 sm:text-lg text-center">
            <h2 class="text-5xl font-bold text-gray-300">Apply Now!</h2>
          </div>
        </div>
      </section>
      <section class="bg-white pt-8 lg:pt-24">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="mb-12 text-5xl font-bold text-gray-600">
              Application process
            </h2>
            <p class="mb-4 font-medium text-xl text-gray-500">
              Commitment to a lifelong journey of learning and self-improvement!
              <br />
              <br />
              Show us that you have the skills and abilities to grab things fast
              and are determined to work hard to become a successful software
              developer.
              <br />
              <br />
              Send your details using this form and take the quick assessment
              using the link given below.
              <br />
              <br />
              <a
                href="/"
                class="text-purple-600 underline hover:text-purple-700"
              >
                Take the assessment
              </a>
            </p>
          </div>

          <form onSubmit={onSubmit}>
            <div class="bg-white bg-gradient-to-r from-gray-300 to-gray-200 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 rounded-lg p-8">
              <h2 class="text-gray-800 text-xl mb-1 font-bold title-font pb-12">
                Send your details
              </h2>
              <div class="relative mb-4">
                <input
                  type="text"
                  required
                  id="name"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Name"
                  name="name"
                  class="w-full bg-white rounded border border-gray-300 focus:border-gray-500 focus:ring-2 focus:ring-gray-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div class="relative mb-4">
                <input
                  type="email"
                  required
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  name="email"
                  class="w-full bg-white rounded border border-gray-300 focus:border-purple-500 focus:ring-2 focus:ring-purple-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div class="relative mb-4">
                <input
                  type="mobile"
                  required
                  id="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Mobile"
                  name="mobile"
                  class="w-full bg-white rounded border border-gray-300 focus:border-gray-500 focus:ring-2 focus:ring-gray-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div class="relative mb-4">
                <textarea
                  type="text"
                  id="message"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                  name="message"
                  class="w-full bg-white rounded border border-gray-300 focus:border-gray-500 focus:ring-2 focus:ring-gray-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <button class="text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded text-lg">
                Send
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Application;
