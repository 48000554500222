import { Header } from './Header/Header';
import Footer from './Footer';
import ShopMenu from './Shop/ShopMenu';

export const Shop = () => {
  return <div>
      <Header />
      <section class="bg-gray-100 pt-20">
        <ShopMenu />
      </section>
      <Footer />
  </div>
}