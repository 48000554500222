import { Header } from '../Header/Header';
import Footer from '../Footer';
import './TermsOfUse.css';

export const TermsOfUse = () => {
    return <div>
        <Header />
        <section class="body-font relative py-24 text-lg font-medium">
            <div class="">
                <h1 class="term-title">Terms of Use</h1>
                <p class="term-para">Legal Information & Notices</p>
            </div>
            
            <div class="term-line"></div>
            
            <div class="term-content">
                <p class="term-content-para"><a class="text-blue-500" href="https://chainscript.dev/">www.chainscript.dev</a>
                    (the “Site”) is a website operated by ChainScript Labs Private Limited (“we”, “us”, “our”). We are a private 
                    limited company registered in Chennai 
                    ChainScript Labs Private Limited. 
                    To contact us, please email <a class="text-blue-500" href="mailto:contact@chainscript.in">contact@chainscript.in</a> </p>
                <p class="term-content-para">By using our Site, you confirm that you accept these Terms of Use (“Terms) and you agree to comply with them. 
                    If you do not agree to these Terms, you must not use our Site.
                </p>
                <h3 class="term-content-heading">Other terms that may apply</h3>
                <p class="term-content-para">We will collect and process the following data about you:</p>
                <li class="term-content-list">
                    <a class="text-blue-500" href="/privacypolicy">Privacy Policy</a>,  which sets out how we process personal data we receive from you. If you do not wish your personal data to be processed in this way, you should not use this Site.
                </li>
                <li class="term-content-list">
                    <a class="text-blue-500" href="/cookies">Cookie Notice</a>,  which sets out information about the cookies on our Site. This is contained in the Privacy Policy.
                </li>
                <p class="term-content-para">In some instances, additional terms and conditions, such as a 
                    software or product license, may apply to a service or product offered via the Site (“Additional Terms”). T
                    o the extent there is a conflict between these Terms and any Additional Terms, the Additional Terms will control 
                    with respect to such service or product unless the Additional Terms expressly state otherwise. 
                </p>
                <h3 class="term-content-heading">Changes to these Terms and to our Site</h3>
                <p class="term-content-para"> We amend these Terms from time to time. Please check these Terms to ensure you understand the provisions that apply. </p>
                <p class="term-content-para"> We may also update and change our Site from time to time. We do not guarantee that our Site, or any content 
                    on it, will always be available or be uninterrupted and we may suspend or withdraw or restrict the availability
                    of all or any part of our Site at any time. You are responsible for ensuring that all persons accessing our Site 
                    through your internet connection are aware of these Terms of Use and other applicable terms and conditions, and that 
                    they comply with them.  
                </p>
                <h3 class="term-content-heading">Using material on our Site</h3>
                <p class="term-content-para">We are the owner or the licensee of all intellectual property rights in our Site, and in the material published on it. 
                    Those works are protected by copyright laws and all such rights are reserved. You may print off one copy, and download 
                    extracts, of any page(s) for your personal use and you may draw the attention of others within your organisation to content 
                    posted on our Site. </p>
                <p class="term-content-para">You must not modify materials you have printed off or downloaded, and you must not use illustrations, photographs, 
                    video or audio sequences separately from accompanying text. Our status (and that of any identified contributors) as 
                    authors of content on our Site must always be acknowledged.  </p>
                <p class="term-content-para"> You must not use any part of the content on our Site for commercial purposes without obtaining a licence to do so 
                    from us or our licensors via <a class="text-blue-500" href="mailto:contact@chainscript.in">contact@chainscript.in</a> 
                    </p>
                <h3 class="term-content-heading">Obtain advice before relying on information on this Site</h3>
                <p class="term-content-para">The content on our Site is provided for general information only and is not intended to amount to advice on which you 
                    should rely. You must obtain professional advice before taking, or refraining from, any action on the basis of content 
                    on our Site.</p>
                <p class="term-content-para">Although we make reasonable efforts to update the information on our Site, we make no representations, warranties or guarantees, 
                    whether express or implied, that the content on our Site is accurate, complete or up to date.
                </p>
                <h3 class="term-content-heading">Websites we link to</h3>
                <p class="term-content-para"> Where our Site contains links to other sites and resources provided by third parties, these links are provided for your 
                    information only. Such links should not be interpreted as approval by us of those linked websites or information you may 
                    obtain from them. We have no control over the contents of those sites or resources.
                </p>
                <h3 class="term-content-heading">Loss or damage</h3>
                <p class="term-content-para">We do not exclude or limit our liability to you where it would be unlawful for us to do so. This includes liability 
                    for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors, 
                    and liability for fraud or fraudulent misrepresentation. </p>
                <p class="term-content-para">If you are a business user:
                </p>
                <li class="term-content-list">We exclude all implied conditions, warranties, representations or other terms that may apply to our Site or any content on it.</li>
                <li class="term-content-list">We will not be liable to you for loss or damage, whether in contract, tort (including negligence), breach of statutory 
                    duty, or otherwise, arising under or in connection with use of, or inability to use, our Site or use of or reliance on 
                    any content displayed on our Site. In particular, we will not be liable for loss of profits, sales, business, or revenue; 
                    business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; or any indirect 
                    or consequential loss or damage.</li>
                <p class="term-content-para">If you are a consumer user:</p>
                <li class="term-content-list">Please note that we only provide our Site for domestic and private use. You agree not to use our Site for any 
                    commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business 
                    interruption, or loss of business opportunity.</li>
                <li class="term-content-list">We will not be liable to you for loss or damage, whether in contract, tort (including negligence), breach of 
                    statutory duty, or otherwise, arising under or in connection with use of, or inability to use, our Site or use 
                    of or reliance on any content displayed on our Site. In particular, we will not be liable for loss of profits, sales,
                    business, or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or 
                    reputation; or any indirect or consequential loss or damage.</li>
                <h3 class="term-content-heading">Viruses</h3>
                <p class="term-content-para">We do not guarantee that our Site will be secure or free from bugs or viruses. You are responsible for 
                    configuring your information technology, computer programmes and platform to access our Site. You should use your 
                    own virus protection software. </p>
                <p class="term-content-para">You must not misuse our Site by knowingly introducing viruses, trojan horses, worms, logic bombs or other malicious 
                    or technologically harmful material. You must not attempt to gain unauthorised access to our Site, the server on which 
                    our Site is stored or any server, computer or database connected to our Site. You must not attack our Site via a 
                    denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a 
                    criminal offence under relevant laws and such breaches will be reported to the relevant law enforcement authorities. In 
                    the event of such a breach, your right to use our Site will cease immediately.
                </p>
                <h3 class="term-content-heading">Linking to our site</h3>
                <p class="term-content-para">We grant you the revocable permission to link to the Site for commentary or information-sharing purposes; provided, however,
                    that your website, or any third party websites that link to our Site: (a) cannot frame or create a browser or border 
                    environment around any of the content on our Site or otherwise mirror any part of our Site; (b) can not imply that we or
                    the Site are endorsing or sponsoring it or its products, unless we have given our prior written consent; (c) cannot present 
                    false information about or disparage us, our products or services; (d) cannot use any of our trademarks without our prior 
                    written permission; (e) has to be owned and controlled by you or the person or entity placing the link, or otherwise permit
                    you to enable such link subject to these Terms of Use. By linking to this Site, you agree that you do and will continue to 
                    comply with the above linking requirements. Notwithstanding anything to the contrary contained in these Terms of Use, we 
                    reserve the right to prohibit linking to our Site for any reason, in our sole and absolute discretion, even if the linking 
                    complies with the requirements described above.
                </p>
                <h3 class="term-content-heading">Termination</h3>
                <p class="term-content-para">We reserve the right to terminate your access to and use of the Site, without notice and liability, including, 
                    without limitation, if we believe your conduct fails to conform with these Terms of Use. We also reserve the right 
                    to investigate suspected violations of these Terms of Use.
                </p>
                <h3 class="term-content-heading">Which country’s laws apply to any disputes?</h3>
                <p class="term-content-para">These Terms of Use, their subject matter and their formation (and any non-contractual disputes or claims) are governed 
                    by the laws of the Republic of India.</p>
                
            </div>
        </section>      
        <Footer />
    </div>
}