import React from 'react';

const Role = () => {
    return <div>
    <section class="pt-8 lg:pt-24">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl ">

        <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          <div class="m-4">
            <p class="text-4xl font-medium text-gray-800  mx-auto">Are you...</p>
          </div>
          <div class="p-2 w-full">
            <div class="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg fill="none" stroke="#b100ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-purple-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                <path d="M22 4L12 14.01l-3-3"></path>
              </svg>
              <span class="title-font font-medium text-lg">An aspiring software developer?</span>
            </div>
          </div>
          <div class="p-2 w-full">
            <div class="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg fill="none" stroke="#b100ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-purple-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                <path d="M22 4L12 14.01l-3-3"></path>
              </svg>
              <span class="title-font font-medium text-lg">A fresher seeking employment?</span>
            </div>
          </div>
          <div class="p-2 w-full">
            <div class="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg fill="none" stroke="#b100ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-purple-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                <path d="M22 4L12 14.01l-3-3"></path>
              </svg>
              <span class="title-font font-medium text-lg">Interested in learning blockchain development?</span>
            </div>
          </div>
          <div class="p-2 w-full">
            <div class="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg fill="none" stroke="#b100ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-purple-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                <path d="M22 4L12 14.01l-3-3"></path>
              </svg>
              <span class="title-font font-medium text-lg">An experienced software, blockchain developer?</span>
            </div>
          </div>
          <div class="p-2 w-full">
            <div class="bg-gray-100 rounded flex p-4 h-full items-center">
              <svg fill="none" stroke="#b100ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-purple-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                <path d="M22 4L12 14.01l-3-3"></path>
              </svg>
              <span class="title-font font-medium text-lg">Interested in building next-gen blockchain solutions?</span>
            </div>
          </div>

        </div>

        <div class=" mt-16 lg:mt-32 text-gray-500 sm:text-lg">
          <p class="font-medium text-5xl lg:text-6xl text-gray-800">If your answer is yes to any of the above, <br />You are in the right place ...</p>
          <span class="inline-block h-1 w-20 rounded bg-gray-700 mt-8 mb-6"></span>
        </div>
      </div>

 
    </section>
    </div>
}

export default Role