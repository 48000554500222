import React from 'react';

const RoleIndividual = () => {
  const vacancies = [
    {
        id: 1,
        title: 'Full Stack Developer',
        link: 'full-stack-developer'
    },
    {
        id: 2,
        title: 'Flutter Developer',
        link: 'flutter-developer'
    },
    {
        id: 3,
        title: 'Graphic Designer',
        link: 'graphic-designer'
    },
  ]
  return <div>
    <section class="pt-8 lg:pt-12">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="mb-12 text-4xl font-medium text-gray-800">Are You an Aspiring Software Developer?</h2>
            <p class="mb-8 leading-relaxed text-lg font-medium">Don't have a relevant college degree but passionate about software development? Join our 4-month intensive training program at no cost and kickstart your career as a software developer. Prove your quick learning ability and determination!
              <br /><br />Take a quick test to qualify for our DEV school (Limited seats available!)  
              <br /><br /><a class="underline" href="/devschool">Learn More About DEV School</a>
              <br /><br /><a class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg" href="/application">Apply Here</a>
            </p>
          </div>
    
          <div class="flex justify-center">
            <lottie-player class="common-lotte-file" src="https://assets7.lottiefiles.com/packages/lf20_2glqweqs.json"  background="transparent"  speed="1"  loop  autoplay></lottie-player>
          </div>
        </div>
        
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="mb-12 text-4xl font-medium text-gray-800">Freshers Seeking Employment</h2>
            <p class="mb-8 leading-relaxed text-lg font-medium">No experience? No problem! Show us your eagerness to become a developer, and let's overcome your inexperience together.
              <br /><br />
              Apply Here for a quick test and interview shortlisting.
            </p>
            <a class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg" href="/application">Apply Here</a>
          </div>

        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets7.lottiefiles.com/packages/lf20_jtbfg2nb.json"  background="transparent"  speed="1"  loop  autoplay></lottie-player>
        </div>
      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg">
              <h2 class="mb-12 text-4xl font-medium text-gray-800">Interested in Learning Blockchain Development?</h2>
              <p class="mb-8 leading-relaxed text-lg font-medium">Chainscript offers comprehensive guidance in blockchain and Bitcoin development. Perfect for experienced software developers looking to pivot into blockchain.
                <br /><br />Send Us Your CV - <a class="underline" href='mailto:careers@chainscript.dev'>careers@chainscript.dev</a>
              </p>
          </div>

          <div class="flex justify-center">
            <lottie-player class="common-lotte-file" src="https://assets3.lottiefiles.com/packages/lf20_9ungcrzx.json" background="transparent"  speed="1"  loop  autoplay></lottie-player>
          </div>
      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg">
              <h2 class="mb-12 text-4xl font-medium text-gray-800">Experienced Software or Blockchain Developer?</h2>
              <p class="mb-8 leading-relaxed text-lg font-medium">Join our team to innovate in blockchain applications across various industries. We value detail-oriented professionals with a commitment to learning and self-management.
                <br /><br />Apply Here for a Rewarding Career
              </p>
              <p class="mb-8 leading-relaxed text-lg font-medium">
                <a class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg" href="/application">Apply here!</a>
              </p>
          </div>
      
          <div class="flex justify-center">
            <lottie-player class="common-lotte-file" src="https://assets3.lottiefiles.com/packages/lf20_w51pcehl.json"  background="transparent"  speed="1"  loop  autoplay></lottie-player>
          </div>
      </div>
    </section>

    <section class=" pt-8 lg:pt-8">
      <div class="gap-16 items-center py-4 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets8.lottiefiles.com/packages/lf20_mjr11zfo.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
        </div>
        <div class="font-light text-gray-500 sm:text-lg">
          <h2 class="mb-12 text-4xl font-medium text-gray-800">Build a Career at Chainscript</h2>
          <p class="mb-8 leading-relaxed text-lg font-medium">We're expanding our team to create cutting-edge applications on the BSV Blockchain. If you're a professional with keen attention to detail, a passion for learning, and excellent self-management skills, we want you!
            <br /><br /><a href="/application" class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg">Apply now</a>
          </p>
        </div>
      </div>
    </section>

    <section id="devschool" class="pt-8 lg:pt-24">
      <div class="gap-16 pb-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:pb-16 lg:px-6">
        <div class="">
     
        </div>

        <div class="font-light text-gray-500 sm:text-lg container mx-auto">
          <h2 class="mb-12 text-4xl font-medium text-gray-800">Vacancies</h2>

          <div class="mx-auto max-w-screen-xl ">

            <div class="flex flex-wrap sm:mx-auto sm:mb-2 -mx-2">
              {
                vacancies.map(({ id, title, link }) => (
                  <div key={id} class="pt-2 w-full">
                    <div class="rounded flex p-2 h-full items-center border-b border-gray-700">
                      <img src={'static/icon/cube-outline.svg'} class="w-4 h-4 mr-4" alt={title}  />
                      <span class="title-font font-medium hover:text-gray-900 text-lg"><a href={link}>{title}</a></span>
                    </div>
                  </div>
                ))
              }
              
            </div>
          </div>
        </div>
        
      </div>
    </section>
  </div>
}

export default RoleIndividual