import React from 'react';
import './Animation.css';

const Animation = () => {
    return <div>
    <section class="home_animation py-4 lg:py-4">
      <div class="gap-16 items-center py-8 px-12 mx-auto max-w-screen-xl">
        <div class="font-light text-gray-500">
          <h2 class="text-lg lg:text-3xl lg:text-center uppercase leading-relaxed  font-medium text-white dark:text-white">From One Internet to One Blockchain: Embracing a Unified Global Ledger as the Singular Source of Truth</h2>
          <br />
          <h2 class="text-lg lg:text-3xl lg:text-center uppercase leading-relaxed  font-medium text-white dark:text-white">One blockchain world!</h2>
          <h2 class="text-lg lg:text-3xl lg:text-center uppercase leading-relaxed  font-medium text-white dark:text-white">One global ledger!</h2>
          <h2 class="text-lg lg:text-3xl lg:text-center uppercase leading-relaxed  font-medium text-white dark:text-white">A single source of truth!</h2>
        </div>
      </div>
    </section>
    </div>
}

export default Animation
