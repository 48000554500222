import { Header } from './Header/Header';
import Footer from './Footer';
import DevHero from './Devschool/DevHero';
import { Devprogram } from './Devschool/Devprogram/Devprogram';
import DevCourse from './Devschool/DevCourse';

export const Devschool = () => {
    return <div>
        <Header />
        <DevHero />
        <Devprogram />
        <DevCourse />
        <Footer />
    </div>
}