import { Header } from './Header/Header';
import Footer from './Footer';
import TogetherCareer from './Career/TogetherCareer/TogetherCareer';
import Role from './Career/Role';
import RoleIndividual from './Career/RoleIndividual';

export const Career = () => {
    return <div>
        <Header />
        <TogetherCareer />
        <Role />
        <RoleIndividual />
        <Footer />
    </div>
}