import React from 'react';

const Office = () => {
  return (
    <div>
      <section class="pt-8 lg:pt-24">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg">
            <h2 class="mb-12 text-4xl font-medium text-gray-800">Our office</h2>
            <p class="mb-4 font-medium text-xl text-gray-500">
              Nestled in the tranquil valleys of the Western Ghats and overlooking the verdant paddy fields near Tenkasi, our office stands as a testament to our commitment to counter urbanization and champion sustainable village living.
            </p>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-8">
            <img
              class="w-full rounded-lg"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
              alt="office content 1"
            />
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
              alt="office content 2"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Office;
