import React from 'react';

const Features = () => {
    return <div>
    <section class="bg-white pt-8 lg:pt-24">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl  lg:px-6">
        <div class="font-light text-center text-gray-500 sm:text-lg dark:text-gray-400">
          <span class="inline-block h-1 w-20 rounded bg-gray-700 mt-4 mb-6"></span>
          <p class="mb-4 font-medium text-4xl text-gray-800">A blockchain that promises the following features</p>
        </div>
      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6 border-b border-gray-300">
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-medium text-gray-600">Scalable</h2>
          <p class="mb-4 font-medium text-lg text-gray-500">Massive Scalability: Our vision encompasses trillions of IoT devices communicating peer-to-peer every second, generating billions of transactions.</p>
        </div>
        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets5.lottiefiles.com/private_files/lf30_h8h2h0v7.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
        </div>
      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6 border-b border-gray-300">
        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets5.lottiefiles.com/packages/lf20_buhqqgcm.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
        </div>
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-medium text-gray-600">Security</h2>
          <p class="mb-4 font-medium text-lg text-gray-500">Unmatched Security: So robust that it's suitable for central bank digital currencies to settle trade finance and cross-border payments over our secure, proof-of-work network.</p>
        </div>
      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6 border-b border-gray-300">
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-medium text-gray-600">Stability</h2>
          <p class="mb-4 font-medium text-lg text-gray-500">Guaranteed Stability: Engineered for enduring reliability, our platform ensures that applications remain stable for years to come.</p>
        </div>
        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets5.lottiefiles.com/packages/lf20_rrqimc3f.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
        </div>
      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6 border-b border-gray-300">
        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets6.lottiefiles.com/packages/lf20_8lqexrip.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
        </div>
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-medium text-gray-600">Swift</h2>
          <p class="mb-4 font-medium text-lg text-gray-500">Safe and Swift Transactions: Facilitate ultra-fast nano payments across borders and oceans, ideal for games, videos, and microservices.</p>
        </div>

      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6 border-b border-gray-300">
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-medium text-gray-600">Sustainable</h2>
          <p class="mb-4 font-medium text-lg text-gray-500">Unparalleled Efficiency: Revolutionizing global payment rails, notary services, and financial transactions with sustainable, cost-effective solutions.</p>
        </div>
        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets8.lottiefiles.com/packages/lf20_n9lzznbu.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
        </div>
      </div>

      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6">
        <div class="flex justify-center">
          <lottie-player class="common-lotte-file" src="https://assets2.lottiefiles.com/packages/lf20_DVSwGQ.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
        </div>
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-3xl font-medium text-gray-600">Smart</h2>
          <p class="mb-4 font-medium text-lg text-gray-500">Embedded Intelligence: Digitize real-world assets and automate applications in diverse sectors, including medicine, insurance, and supply chains.</p>
        </div>

      </div>
      </section>
    </div>
}

export default Features
