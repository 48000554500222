import { Header } from '../Header/Header';
import Footer from '../Footer';
import './PrivacyPolicy.css';

export const PrivacyPolicy = () => {
    return <div>
        <Header />
        <section class="body-font relative py-24 text-lg font-medium">
            <div class="">
                <h1 class="pri-title">Privacy Policy</h1>
                <p class="pri-para mt-4">We are committed to protecting and respecting your privacy.</p>
            </div>

            <div class="pri-line"></div>

            <div class="pri-content">
                <h3 class="pri-content-heading">Your privacy is important to us.</h3>
                <p class="pri-content-para">
                    This Privacy Policy (together with our Terms of Use (and any documents
                    referred to in it) sets out the basis on which any personal data we
                    collect from you, or that you provide to us, will be processed by us.
                    Please read the following carefully to understand our practices
                    regarding your personal data and how we treat it. If you do not wish
                    your personal data to be used in the ways set out in this Privacy
                    Policy, you should not use this website.
                </p>
                <h3 class="pri-content-heading">Information we collect from you.</h3>
                <p class="pri-content-para">We will collect and process the following data about you:</p>
                <li class="pri-content-list">
                    Information you give us. This is information about you that you give
                    us by filling in forms on our website (the “Site“) or by corresponding
                    with us by phone, e-mail or otherwise. It includes registration
                    information, information you provide when you submit your CV/contact
                    details for a job application, or if you report a problem with our
                    Site. The information you give may include your name, address, e-mail
                    address, and phone number.
                </li>
                <li class="pri-content-list">
                    Information we collect about you. We may collect the following
                    information relating to your visits
                </li>
                <li class="pri-content-list">
                    technical information, including the Internet protocol (IP) address
                    used to connect your computer to the Internet, browser type and
                    version, time zone setting, browser plug-in types and versions,
                    operating system and platform;
                </li>
                <li class="pri-content-list">
                    information about your visit, including the full Uniform Resource
                    Locators (URL), clickstream to, through and from our Site (including
                    date and time), page response times, download errors, length of visits
                    to certain pages, page interaction information (such as scrolling,
                    clicks, and mouse-overs), or methods used to browse away from the
                    page.
                </li>
                <li class="pri-content-list">
                    Information we receive from other sources. This is information we
                    receive about you if you use any of the other websites we operate or
                    the other services we provide. In this case we will have informed you
                    (through such other websites or other services we provide) when we
                    collected that data if we intend to share those data internally and
                    combine it with data collected on this Site. We will also have told
                    you for what purpose we will share and combine your data. We may also
                    work with third parties (including, for example, business partners,
                    sub-contractors in technical, payment and delivery services,
                    advertising networks, analytics providers, search information
                    providers, credit reference agencies).
                </li>
                <h3 class="pri-content-heading">Uses made of the information</h3>
                <p class="pri-content-para">We use information held about you in the following ways:</p>
                <p class="pri-content-para">Information you give to us</p>
                <p class="pri-content-para">
                    This is information about you that you give us by filling in forms on
                    our Site or by corresponding with us by phone, e-mail or otherwise. It
                    includes information you provide, for example, when you register to
                    use our Site, subscribe to any services we provide, and when you
                    report a problem with our Site. The information you give us may
                    include your name, address, e-mail address and phone number. We use
                    this information in the following ways:
                </p>
                <li class="pri-content-list">
                    Where we need to use the information to carry out our obligations
                    arising from contracts entered into between you and us and to provide
                    you with the information, products and services that you request from
                    us.
                </li>
                <li class="pri-content-list">
                    We will retain and use details of the goods and services that you have
                    previously purchased or enquired about to make suggestions to you for
                    other products which we believe you will also be interested in. We do
                    this where necessary for our legitimate business purposes of providing
                    you with more tailored offers and personalising your experience on our
                    Site.
                </li>
                <li class="pri-content-list">
                    If you have signed up to attend one of our events, access publications
                    or receive our marketing materials, personal data including your name,
                    email address, telephone number and other contact information will be
                    collected at the time you sign up. We may use the information we
                    collect to provide you with information on events, publications,
                    products or services that we feel may interest you. You will be able
                    to specify your contact preferences when you register to attend an
                    event or receive communications, products or services from us. You can
                    also change your contact preferences at any time using the options
                    which can be found on relevant communications or by contacting us
                    directly at 
                    <a class="text-blue-500" href="mailto:contact@chainscript.in"> contact@chainscript.in</a>
                </li>
                <h3 class="pri-content-heading">Information we collect from you</h3>
                <p class="pri-content-para">
                    When you visit our Site, we may use cookies and similar technologies
                    to automatically collect the following information:
                </p>
                <li class="pri-content-list">
                    Technical information, including your IP address, your login
                    information, browser type and version, time zone setting, browser
                    plug-in types and versions, and operating system and platform;
                </li>
                <li class="pri-content-list">
                    Information about your visit, including the full Uniform Resource
                    Locators (URL), clickstream to, through and from our Site (including
                    date and time), products or services you viewed or searched for page
                    response times, length of visits to certain pages, and page
                    interaction information (such as scrolling, clicks, and mouse-overs).
                </li>
                <p class="pri-content-para">
                    To learn more about how we use cookies and how to control which
                    cookies are used, please see our 
                <a class="text-blue-500" href="/cookienotice"> Cookie Notice.</a>
                </p>
                <p class="pri-content-para">We use the information we collect from you in the following ways:</p>
                <li class="pri-content-list">
                    To administer our Site and for internal operations, including
                    troubleshooting, data analysis, testing, research, statistical and
                    survey purposes. This is necessary for our legitimate business
                    purposes to monitor our services and ensure our Site is functioning
                    properly.
                </li>
                <li class="pri-content-list">
                    We will retain and evaluate information on your recent visits to our
                    Site and how you move around different sections of the Site to
                    understand how people use it. Again, we do this for legitimate
                    business purposes, so that we can improve our Site and enhance our
                    users’ experience.
                </li>
                <li class="pri-content-list">
                    We use technical information and information about your visits to our
                    Site where this is necessary in our legitimate business interests to
                    keep our Site safe and secure
                </li>
                <li class="pri-content-list">
                    We will analyse information on your recent visits to our site and your
                    past purchases to measure and understand the effectiveness of any
                    advertising we may serve to you and others, where this is necessary
                    for the legitimate business purpose of tracking the effectiveness of
                    our marketing and online advertising.
                </li>
                <h3 class="pri-content-heading">Information we receive from other sources</h3>
                <p class="pri-content-para">
                    This is information we receive about you if you use any of the other
                    websites we operate or the other services we provide We may work with
                    third parties (including, for example, business partners,
                    sub-contractors in technical, payment and delivery services,
                    advertising networks, analytics providers, search information
                    providers, credit reference agencies). These third parties may share
                    information with us in accordance with their own data protection
                    policies and procedures.
                </p>
                <p class="pri-content-para">
                    Please note that third parties have their own privacy policies and
                    that we have no control over how third parties use personal
                    information. You should check the privacy policies of third party
                    sites before you submit any personal information to these websites.
                </p>
                <p class="pri-content-para">
                    We may combine information you give to us with any information we
                    collect about you and/or information we receive from other sources and
                    use it in the ways set out above (depending on the types of
                    information we receive) where this is necessary for our legitimate
                    business purposes in providing you with a better and more tailored
                    experience.
                </p>
                <h3 class="pri-content-heading">Disclosure of your information</h3>
                <p class="pri-content-para">We may share your personal information with:</p>
                <li class="pri-content-list">
                    Any member of our group, which means our affiliates and subsidiaries,
                    our ultimate holding company and its subsidiaries and affiliates
                </li>
                <li class="pri-content-list">
                    Selected third parties including business partners, licensees,
                    suppliers and sub-contractors for the performance of any contract we
                    enter into with them or you; or
                </li>
                <li class="pri-content-list">
                    Selected third parties who may use this information to send you
                    details of products and services that may interest you (provided you
                    have consented to this); and
                </li>
                <li class="pri-content-list">
                    Analytics and search engine providers that assist us in the
                    improvement and optimization of our Site.
                </li>
                <p class="pri-content-para">We may also disclose your personal information to third parties</p>
                <li class="pri-content-list">
                    In the event that we sell or buy any business or assets, in which case
                    we may disclose your personal data to the prospective seller or buyer
                    of such business or assets.
                </li>
                <li class="pri-content-list">
                    If we, or substantially all of our assets are acquired by a third
                    party, in which case personal data held by us about its customers may
                    be one of the transferred assets.
                </li>
                <li class="pri-content-list">
                    If we are under a duty to disclose or share your personal data in
                    order to comply with any legal obligation, or in order to enforce or
                    apply our Terms of Use or to protect the rights, property, or safety
                    of our company, our customers, licensees, business partners,
                    affiliates, subsidiaries, or others.
                </li>
                <h3 class="pri-content-heading">Where we store personal data</h3>
                <p class="pri-content-para">
                    The personal data that we collect may be transferred to, and stored
                    at, a destination outside the Republic of England and wales. It may be
                    processed by staff outside England and wales who work for us or for
                    one of our suppliers engaged in the processing of your personal
                    details. We will take all reasonable and necessary steps to ensure
                    your personal data is treated securely and in accordance with this
                    Privacy Policy and applicable Data protection Laws.
                </p>
                <p class="pri-content-para">
                    The personal data that we collect may be transferred to, and stored
                    at, a destination outside the Republic of England and wales. It may be
                    processed by staff outside England and wales who work for us or for
                    one of our suppliers engaged in the processing of your personal
                    details. We will take all reasonable and necessary steps to ensure
                    your personal data is treated securely and in accordance with this
                    Privacy Policy and applicable Data protection Laws.
                </p>
                <h3 class="pri-content-heading">Your rights</h3>
                <p class="pri-content-para">
                    You have the right to ask us at any time not to process your personal
                    data for marketing purposes, or to stop processing your data for these
                    purposes if you have previously agreed to this. You can exercise your
                    right to prevent the processing of your personal data for marketing
                    purposes by checking certain boxes on the forms we use to collect your
                    data. If you have already agreed to receive marketing communications
                    from us but have now changed your mind, you can easily unsubscribe at
                    any time by following the instructions (such as to click an
                    “Unsubscribe” link in emails) included in these marketing
                    communications. Alternatively, you can email us at 
                    <a class="text-blue-500" href="mailto:contact@chainscript.in"> contact@chainscript.in</a>
                </p>
                <p class="pri-content-para">
                    Our Site may contain links to and from websites of our partner
                    networks, advertisers and affiliates. If you follow a link to these
                    websites, they have their own privacy policies and we do not accept
                    any responsibility or liability for these policies. Please check these
                    policies before you submit any personal data to these websites.
                </p>
                <h3 class="pri-content-heading">Access to information</h3>
                <p class="pri-content-para">
                    Under applicable data protection laws (to the extent they apply to the
                    Site and to you), you have the right to:
                </p>
                <li class="pri-content-list">
                    Access. You have the right to request a copy of the personal
                    information we are processing about you, which we will provide back to
                    you in electronic form. For your own privacy and security, in our
                    discretion we may require you to prove your identity before providing
                    the requested information. If you require multiple copies of your
                    personal data, we may charge a reasonable administration fee.
                </li>
                <li class="pri-content-list">
                    Rectification. You have the right to have incomplete or inaccurate
                    personal information that we process about you corrected. Note that
                    you can always make certain adjustments to certain personal
                    information directly through your online account.
                </li>
                <li class="pri-content-list">
                    Deletion. You have the right to request that we delete personal
                    information that we process about you, except we are not obligated to
                    do so if we need to retain such data in order to comply with a legal
                    obligation or to establish, exercise or defend legal claims.
                </li>
                <li class="pri-content-list">
                    Restriction. You have the right to restrict our processing of your
                    personal information where you believe such data to be inaccurate, our
                    processing is unlawful or that we no longer need to process such data
                    for a particular purpose, but where we are not able to delete the data
                    due to a legal or other obligation or because you do not wish for us
                    to delete it.
                </li>
                <li class="pri-content-list">
                    Portability. You have the right to obtain personal information we hold
                    about you, in a structured, electronic format, and to transmit such
                    data to another data controller, where this is (a) personal
                    information which you have provided to us, and (b) if we are
                    processing that data on the basis of your consent (such as for direct
                    marketing communications) or to perform a contract with you.
                </li>
                <li class="pri-content-list">
                    Objection. Where the legal justification for our processing of your
                    personal information is our legitimate interest, you have the right to
                    object to such processing on grounds relating to your particular
                    situation. We will abide by your request unless we have compelling
                    legitimate grounds for the processing which override your interests
                    and rights, or if we need to continue to process the data for the
                    establishment, exercise or defence of a legal claim.
                </li>
                <li class="pri-content-list">
                    Withdrawing Consent. If you have consented to our processing of your
                    personal information, you have the right to withdraw your consent at
                    any time, free of charge This includes cases where you wish to opt out
                    from marketing messages that you receive from us.
                </li>
                <h3 class="pri-content-heading">Security and storage of information</h3>
                <p class="pri-content-para">
                    Your personal information is processed by us and/or our service
                    providers only for the period necessary for the purposes for which the
                    information is collected. When we no longer need to use your
                    information for those purposes, we will remove it from our systems and
                    records or take steps to anonymise it so that you can no longer be
                    identified from it (unless we need to keep your information to comply
                    with legal or regulatory obligations to which we are subject).
                </p>
                <h3 class="pri-content-heading">Changes to our Privacy Policy</h3>
                <p class="pri-content-para">
                    Any changes we make to our Privacy Policy in the future will be posted
                    on this page.
                </p>
            </div>
        </section>       
        <Footer />
    </div>
}