import { Header } from './Header/Header';
import Footer from './Footer';

export const GraphicDesigner = () => {
  return <div>
        <Header />
        <section class="bg-white pt-24">
            <div class="gap-16 py-4 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div class="grid justify-center lg:pt-20">
                    <img src={'static/icon/graphic.svg'} alt="Graphic designer" width="350px" height="350px" />
                </div>
                <div class="font-light text-gray-500 sm:text-lg">
                    <h2 class="mb-12 text-3xl font-bold text-gray-600">Graphic designer</h2>
                    <p class="mb-4 font-medium text-xl text-gray-500"><strong>Job description</strong> [ Full Time | Remote Work ]
                        <br /><br />We are looking for a Graphic Designer/Illustrator, with up-to-date tool & subject knowledge and trends, to design solutions with high visual impact. You will be working with a dynamic team of Product Managers, Business Development Managers, UI/UX Designers, Developers, and Merchant stakeholders in order to produce beautiful illustrations to showcase the core product/feature in question.
                    </p>
                    <p class="py-4 font-bold text-xl text-gray-500">Mandatory Skills:</p>
                    <ul class="list-disc m-4">
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Proficient with at least one high-fidelity vector based drawing tool: Adobe Illustrator, Corel Draw, Procreate etc. Adobe Photoshop is a plus.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Strong digital design background.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Strong portfolio to showcase creativity and ability to tell stories through illustrations, sketches, drawings, paintings etc.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Creative, ownership driven and has good communication & presentation skills.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Ability to understand complex products and convert to graphical representations within timelines while maintaining quality.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Create icon sets and illustrations from concept to production. Should be able to ideate, sketch variations, and come up with a consistent set of icons and illustrations conforming to the use case and theme of the app/product.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Understand the product(s) to capture the essence as well as understand the target audience to capture their interest.</p></li>
                        <li><p class="mb-2 font-medium text-lg text-gray-500">Use and recommend the best design methods, applications, software and techniques.</p></li>
                    </ul>
                    <p class="mb-8 leading-relaxed text-lg font-medium">
                        <a class="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded-3xl text-lg" href="/application">Apply now</a>
                    </p>
                </div>
        
            </div>
        </section>  
        <Footer />
  </div>
}